import React from "react";
import PropTypes from "prop-types";

const GetScreen = ({ classes, isSectionForm, listPictures }) =>
  isSectionForm ? (
    <picture className={classes}>
      <source
        type="image/webp"
        media="(min-width: 1366px)"
        srcSet={`${listPictures.deskImgWebp} 1x, ${listPictures.deskImgWebp2x} 2x`}
      />
      <source
        media="(min-width: 1366px)"
        srcSet={`${listPictures.deskImgPng} 1x, ${listPictures.deskImgPng2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1366px)"
        srcSet={`${listPictures.mdImgWebp} 1x, ${listPictures.mdImgWebp2x} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1366px)"
        srcSet={`${listPictures.mdImgPng} 1x, ${listPictures.mdImgPng2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 1199px)"
        srcSet={`${listPictures.tabImgWebp} 1x, ${listPictures.tabImgWebp2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 1199px)"
        srcSet={`${listPictures.tabImgPng} 1x, ${listPictures.tabImgPng2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${listPictures.mobImgWebp} 1x, ${listPictures.mobImgWebp2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${listPictures.mobImgPng} 1x, ${listPictures.mobImgPng2x} 2x`}
      />
      <img src={listPictures.deskImgPng} alt="img" loading="lazy" />
    </picture>
  ) : (
    <div className={classes}>
      <picture>
        <source
          type="image/webp"
          media="(min-width: 1366px)"
          srcSet={`${listPictures.deskImgWebp} 1x, ${listPictures.deskImgWebp2x} 2x`}
        />
        <source
          media="(min-width: 1366px)"
          srcSet={`${listPictures.deskImgPng} 1x, ${listPictures.deskImgPng2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 1200px) and (max-width: 1366px)"
          srcSet={`${listPictures.mdImgWebp} 1x, ${listPictures.mdImgWebp2x} 2x`}
        />
        <source
          media="(min-width: 1200px) and (max-width: 1366px)"
          srcSet={`${listPictures.mdImgPng} 1x, ${listPictures.mdImgPng2x} 2x`}
        />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${listPictures.tabImgWebp} 1x, ${listPictures.tabImgWebp2x} 2x`}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={`${listPictures.tabImgPng} 1x, ${listPictures.tabImgPng2x} 2x`}
        />
        <source
          type="image/webp"
          media="(max-width: 991px)"
          srcSet={`${listPictures.mobImgWebp} 1x, ${listPictures.mobImgWebp2x} 2x`}
        />
        <source
          media="(max-width: 991px)"
          srcSet={`${listPictures.mobImgPng} 1x, ${listPictures.mobImgPng2x} 2x`}
        />
        <img src={listPictures.deskImgPng} alt="img" loading="lazy" />
      </picture>
    </div>
  );
GetScreen.propTypes = {
  isSectionForm: PropTypes.bool,
  listPictures: PropTypes.object,
  classes: PropTypes.string,
};

export default GetScreen;
