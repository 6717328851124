import PNG_ImgOne_desk from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_01.png"
import PNG_ImgOne_desk_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_01@2x.png"
import WEBP_ImgOne_desk from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_01.webp"
import WEBP_ImgOne_desk_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_01@2x.webp"
import PNG_ImgOne_md from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_02.png"
import PNG_ImgOne_md_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_02@2x.png"
import WEBP_ImgOne_md from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_02.webp"
import WEBP_ImgOne_md_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_02@2x.webp"
import PNG_ImgOne_tab from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_03.png"
import PNG_ImgOne_tab_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_03@2x.png"
import WEBP_ImgOne_tab from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_03.webp"
import WEBP_ImgOne_tab_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_03@2x.webp"
import PNG_ImgOne_mob from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_04.png"
import PNG_ImgOne_mob_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_04@2x.png"
import WEBP_ImgOne_mob from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_04.webp"
import WEBP_ImgOne_mob_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_01_04@2x.webp"

import PNG_ImgTwo_desk from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_01.png"
import PNG_ImgTwo_desk_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_01@2x.png"
import WEBP_ImgTwo_desk from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_01.webp"
import WEBP_ImgTwo_desk_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_01@2x.webp"
import PNG_ImgTwo_md from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_02.png"
import PNG_ImgTwo_md_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_02@2x.png"
import WEBP_ImgTwo_md from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_02.webp"
import WEBP_ImgTwo_md_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_02@2x.webp"
import PNG_ImgTwo_tab from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_03.png"
import PNG_ImgTwo_tab_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_03@2x.png"
import WEBP_ImgTwo_tab from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_03.webp"
import WEBP_ImgTwo_tab_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_03@2x.webp"
import PNG_ImgTwo_mob from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_04.png"
import PNG_ImgTwo_mob_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_04@2x.png"
import WEBP_ImgTwo_mob from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_04.webp"
import WEBP_ImgTwo_mob_2x from "../../../assets/images/raster/pages/amzscout-vs-jungle-scout/KeywordResearch/KeywordResearch_02_04@2x.webp"

const PG_AVJS_SECTION_FOUR_CONTENT = {
  centralSection: {
    listsPictures: {
      listOne: {
        deskImgPng: PNG_ImgOne_desk,
        deskImgPng2x: PNG_ImgOne_desk_2x,
        deskImgWebp: WEBP_ImgOne_desk,
        deskImgWebp2x: WEBP_ImgOne_desk_2x,
        mdImgPng: PNG_ImgOne_md,
        mdImgPng2x: PNG_ImgOne_md_2x,
        mdImgWebp: WEBP_ImgOne_md,
        mdImgWebp2x: WEBP_ImgOne_md_2x,
        tabImgPng: PNG_ImgOne_tab,
        tabImgPng2x: PNG_ImgOne_tab_2x,
        tabImgWebp: WEBP_ImgOne_tab,
        tabImgWebp2x: WEBP_ImgOne_tab_2x,
        mobImgPng: PNG_ImgOne_mob,
        mobImgPng2x: PNG_ImgOne_mob_2x,
        mobImgWebp: WEBP_ImgOne_mob,
        mobImgWebp2x: WEBP_ImgOne_mob_2x,
      },
      listTwo: {
        deskImgPng: PNG_ImgTwo_desk,
        deskImgPng2x: PNG_ImgTwo_desk_2x,
        deskImgWebp: WEBP_ImgTwo_desk,
        deskImgWebp2x: WEBP_ImgTwo_desk_2x,
        mdImgPng: PNG_ImgTwo_md,
        mdImgPng2x: PNG_ImgTwo_md_2x,
        mdImgWebp: WEBP_ImgTwo_md,
        mdImgWebp2x: WEBP_ImgTwo_md_2x,
        tabImgPng: PNG_ImgTwo_tab,
        tabImgPng2x: PNG_ImgTwo_tab_2x,
        tabImgWebp: WEBP_ImgTwo_tab,
        tabImgWebp2x: WEBP_ImgTwo_tab_2x,
        mobImgPng: PNG_ImgTwo_mob,
        mobImgPng2x: PNG_ImgTwo_mob_2x,
        mobImgWebp: WEBP_ImgTwo_mob,
        mobImgWebp2x: WEBP_ImgTwo_mob_2x,
      },
    },
    firstPartList: [
      [
        { textParagraph: "Keyword Scout" },
        {
          textParagraph:
            "With Keyword Scout, you can search phrases to find related keywords that customers use when searching on Amazon. The tool shows the monthly volume for each term.",
        },
      ],
      [
        { textParagraph: "Reverse-Search" },
        {
          textParagraph:
            "Jungle Scout’s keyword research tool also includes an ASIN Lookup feature that you can use to find the ranking keywords for a product. You can use these insights to learn from your competitors’ listings and adjust your own marketing strategies.",
        },
      ],
      [
        { textParagraph: "Rank Tracker" },
        {
          textParagraph:
            "Jungle Scout comes with a Rank Tracker tool that you can use to monitor your keyword rankings over time. You can also use it to search for other products to track their keyword rankings.",
        },
      ],
    ],
    secondPartList: [
      [
        { textParagraph: "Amazon Keyword Search" },
        {
          textParagraph:
            "The Amazon Keyword Search lets you search phrases to find keywords used by Amazon customers. The results display the term’s volume as well as a graph showing its historical search volume. When you click a term, you are taken to the Product Database where you can review items that rank for the keyword.",
        },
      ],
      [
        { textParagraph: "Reverse ASIN Lookup" },
        {
          textParagraph:
            "The Reverse ASIN lookup tool lets you reverse search a product using its ASIN to discover all the keywords that the product is currently ranking for. This is a fast way to find all the relevant keywords for a potential product.",
        },
      ],
      [
        { textParagraph: "Keyword Tracker Extension" },
        {
          textParagraph:
            "The Keyword Tracker enables tracking the keyword rankings for any product simply by entering its ASIN. You can use it to run cost-effective PPC campaigns by only selecting the best-performing keywords for your products.",
        },
        {
          textParagraph:
            "The extension also has charts and visualizations to show you where your product ranks for a particular keyword and how that position is changing over time.",
        },
      ],
      [
        { textParagraph: "AMZScout PRO Extension" },
        {
          textParagraph:
            "There is also a useful feature in the PRO Extension that you can use for finding keywords. With the feature, you can write one keyword and quickly bring up new keyword ideas by clicking “Niche Keywords”.",
        },
      ],
    ],
  },
  bottomSection: {
    firstPartList: [
      [
        { textParagraph: "Detailed Listing Analysis" },
        {
          textParagraph:
            "In addition to a more thorough scoring system, AMZScout’s listing quality feature also provides more detailed information about the listing. With AMZScout, you can hover over the listing quality score to see a list of pros and cons covering all the important areas of an Amazon listing.",
        },
        {
          textParagraph:
            "You can use these insights to get a better idea of what to focus on to make your listings stand out, should you decide to sell that item.",
        },
      ],
      [
        { textParagraph: "Data from Google Trends" },
        {
          textParagraph:
            "The AMZScout PRO Extension provides more detail into a search term’s interest over time by including data from Google Trends. You can use these insights to see if the demand for that type of product is sustainable.",
        },
      ],
    ],
    secondPartList: [
      [
        { textParagraph: "Extra Tools" },
        {
          textParagraph:
            "AMZScout comes packed with extra tools that you can use to optimize your Amazon listings. For example, the Super URL tool.",
        },
        {
          textParagraph:
            "With the Super URL tool, you can create unique optimized links for each of your products that will help improve your Amazon search rankings. AMZScout has more extra tools than Jungle Scout.",
        },
      ],
    ],
  },
}
export default PG_AVJS_SECTION_FOUR_CONTENT
