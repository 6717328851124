import React from "react";
import AMZS_CONTENT from "./index.content";
import GetList from "../../../GetList";
import GetScreen from "../../../GetScreen";
import "./index.scss";

const AMZS = () => {
  const { screen, paragraphs } = AMZS_CONTENT;

  return (
    <div className="PgAVJS-PR-AMZS">
      <GetScreen classes="PgAVJS-PR-AMZS__imgWrap" listPictures={screen} />
      <div className="PgAVJS-PR-AMZS__textWrap">
        <p className="PgAVJS-PR-AMZS__title">AMZScout Product Research Tools</p>
        <GetList
          list={paragraphs}
          classes="PgAVJS-PR-AMZS__listItem"
          classesImg="PgAVJS-PR-AMZS__marker"
        />
      </div>
    </div>
  );
};

export default AMZS;
