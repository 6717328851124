import React from "react";
import PG_AVJS_SECTION_FIVE_CONTENT from "./index.content";
import GetList from "../GetList";
import "./index.scss";

const SectionFive = () => {
  const { listParagraphs } = PG_AVJS_SECTION_FIVE_CONTENT;

  return (
    <section className="PgAVJS-SectionFive">
      <div className="PgAVJS-SectionFive__wrapRowOne">
        <div className="container">
          <div className="PgAVJS-SectionFive-sectionTitle">
            <h2 className="PgAVJS-SectionFive-sectionTitle__title">
              Learning Materials
            </h2>
            <p className="PgAVJS-SectionFive-sectionTitle__text">
              Last up in our AMZScout vs. Jungle Scout comparison are the
              learning resources. Both platforms offer users educational
              material to help them grow as Amazon sellers.
            </p>
          </div>
          <div className="PgAVJS-SectionFive__centerSection">
            <div className="PgAVJS-SectionFive-columOne">
              <div className="PgAVJS-SectionFive-columOne__textWrap">
                <p className="PgAVJS-SectionFive-columOne__title">
                  Jungle Scout
                </p>
                <p className="PgAVJS-SectionFive-columOne__subTitle">
                  For Jungle Scout, signing up for any one of their paid plans
                  grants you access to the Jungle Scout Academy. This training
                  program contains videos covering every important area of being
                  an Amazon seller.
                </p>
              </div>
            </div>
            <div className="PgAVJS-SectionFive-columTwo">
              <div className="PgAVJS-SectionFive-columTwo__textWrap">
                <p className="PgAVJS-SectionFive-columTwo__title">AMZScout</p>
                <p className="PgAVJS-SectionFive-columTwo__subTitle">
                  AMZ Scout has several learning resources for Amazon sellers.
                </p>
                <GetList
                  list={listParagraphs}
                  classes="PgAVJS-SectionFive-columTwo__listItem"
                  classesImg="PgAVJS-SectionFive-columTwo__marker"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="PgAVJS-SectionFive__wrapRowTwo">
        <div className="container">
          <div className="PgAVJS-SectionFive-bottomSection">
            <p className="PgAVJS-SectionFive-bottomSection__text">
              Overall, the learning resources for the two platforms are
              comparable in quality. However, AMZScout offers sellers more
              educational material than Jungle Scout. Plus, AMZScout has free
              courses that people can use to begin to learn about the process of
              selling on Amazon without having to sign up for a research tool
              first.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFive;
