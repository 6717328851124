import React from "react";
import SVG_LOGOS from "../../../assets/images/vector/pages/amzscout-vs-jungle-scout/BetterTool_logos.svg";
import "./index.scss";

const BetterTool = () => {
  return (
    <section className="PgAVJS-BT">
      <div className="container">
        <div className="PgAVJS-BT__wrap">
          <img className="PgAVJS-BT__img" src={SVG_LOGOS} alt="img" />
          <div className="PgAVJS-BT-sectionTitle">
            <h2 className="PgAVJS-BT-sectionTitle__title">
              Which Tool is Better?
            </h2>
            <p className="PgAVJS-BT-sectionTitle__text">
              The affordability and data accuracy offered by AMZScout sets it
              apart and will make it the go-to option for many Amazon sellers.
            </p>
            <p className="PgAVJS-BT-sectionTitle__text">
              <b>
                After all, you should be investing more in your actual business,
                not the tools that you use.
              </b>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BetterTool;
