import React from "react";
import PG_AVJS_SECTION_FOUR_CONTENT from "./index.content";
import SvgMarker from "../../../assets/images/vector/components/common/tickInACircle.svg";
import GetList from "../GetList";
import GetScreen from "../GetScreen";
import "./index.scss";

const KeywordResearch = () => {
  const { centralSection, bottomSection } = PG_AVJS_SECTION_FOUR_CONTENT;

  return (
    <section className="PgAVJS-KR">
      <div className="PgAVJS-KR__wrapRowOne">
        <div className="container">
          <div className="PgAVJS-KR-sectionTitle">
            <h2 className="PgAVJS-KR-sectionTitle__title">
              Keyword Research and Listings Optimization
            </h2>
            <p className="PgAVJS-KR-sectionTitle__text">
              Keyword research is a critical part of selling on Amazon. With it,
              you can see what search terms your customers are using so that you
              can optimize your listings to appear for those terms. Keyword
              research also gives you insight into a product’s overall demand
              and how much organic traffic you should expect if you can get your
              listing to rank.
            </p>
            <p className="PgAVJS-KR-sectionTitle__text">
              Let’s take a look at the keyword research and listing optimization
              tools offered by AMZScout and Jungle Scout.
            </p>
          </div>
          <div className="PgAVJS-KR__centerSection">
            <div className="PgAVJS-KR-columOne">
              <GetScreen
                classes="PgAVJS-KR-columOne__imgWrap"
                listPictures={centralSection.listsPictures.listOne}
              />
              <div className="PgAVJS-KR-columOne__textWrap">
                <p className="PgAVJS-KR-columOne__title">
                  Jungle Scout Keyword Research Tools
                </p>
                <GetList
                  list={centralSection.firstPartList}
                  classes="PgAVJS-KR-columOne__listItem"
                  classesImg="PgAVJS-KR-columOne__marker"
                />
              </div>
            </div>
            <div className="PgAVJS-KR-columTwo">
              <GetScreen
                classes="PgAVJS-KR-columTwo__imgWrap"
                listPictures={centralSection.listsPictures.listTwo}
              />
              <div className="PgAVJS-KR-columTwo__textWrap">
                <p className="PgAVJS-KR-columTwo__title">
                  AMZScout Keyword Research Tools
                </p>
                <GetList
                  list={centralSection.secondPartList}
                  classes="PgAVJS-KR-columTwo__listItem"
                  classesImg="aPgAVJS-KR-columTwo__marker"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="PgAVJS-KR__wrapRowTwo">
        <div className="container">
          <div className="PgAVJS-KR-bottomSection">
            <p className="PgAVJS-KR-bottomSection__title">Key Differences</p>
            <div className="PgAVJS-KR-bottomSection__listWrap">
              <GetList
                list={bottomSection.firstPartList}
                classes="PgAVJS-KR-bottomSection__listItem"
                img={SvgMarker}
              />
              <GetList
                list={bottomSection.secondPartList}
                classes="PgAVJS-KR-bottomSection__listItem"
                img={SvgMarker}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeywordResearch;
