import React from "react";

const COMPARE_AMZS_CONTENT = [
  [{ textParagraph: "Amazon Seller’s Bundle: $49.99/month ($399.99/year)" }],
  [
    {
      textParagraph:
        "Amazon Seller’s Bundle with Add-on*: $59.99/month ($499.99/year)"
    }
  ],
  [{ textParagraph: "PRO Extension: $279.99/year ($599.99 lifetime membership)" }],
  [{ textParagraph: "Amazon Seller’s Bundle: $1,599.99 lifetime membership" }]
];
export default COMPARE_AMZS_CONTENT;
