import React from "react";
import BOARD_CONTENT from "./index.content";
import "./index.scss";

const Board = () => (
  <div className="PgAVJS-BP-Board">
    {BOARD_CONTENT.map((text, i) => (
      <p key={i} className="PgAVJS-BP-Board__text">
        {text}
      </p>
    ))}
  </div>
);

export default Board;
