import React from "react";
import SvgMarker from "../../../../assets/images/vector/components/common/tickInACircle.svg";
import BOARD_CONTENT from "./index.content";
import GetList from "../../GetList";
import "./index.scss";

const Board = () => {
  const { firstPartList, secondPartList } = BOARD_CONTENT;
  return (
    <div className="PgAVJS-PR-Board">
      <p className="PgAVJS-PR-Board__title">Key Differences</p>
      <p className="PgAVJS-PR-Board__subTitle">
        Here are the most notable differences between the product research tools
        for the two platforms
      </p>
      <div className="PgAVJS-PR-Board__listWrap">
        <GetList
          list={firstPartList}
          classes="PgAVJS-PR-Board__listItem"
          img={SvgMarker}
        />
        <GetList
          list={secondPartList}
          classes="PgAVJS-PR-Board__listItem"
          img={SvgMarker}
        />
      </div>
    </div>
  );
};

export default Board;
