import deskImgTwoPng from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_01.png"
import deskImgTwoPng2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_01@2x.png"
import deskImgTwoWebp from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_01.webp"
import deskImgTwoWebp2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_01@2x.webp"
import mdImgTwoPng from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_02.png"
import mdImgTwoPng2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_02@2x.png"
import mdImgTwoWebp from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_02.webp"
import mdImgTwoWebp2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_02@2x.webp"
import tabImgTwoPng from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_03.png"
import tabImgTwoPng2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_03@2x.png"
import tabImgTwoWebp from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_03.webp"
import tabImgTwoWebp2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_03@2x.webp"
import mobImgTwoPng from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_04.png"
import mobImgTwoPng2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_04@2x.png"
import mobImgTwoWebp from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_04.webp"
import mobImgTwoWebp2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_02_04@2x.webp"

const AMZS_CONTENT = {
  screen: {
    deskImgPng: deskImgTwoPng,
    deskImgPng2x: deskImgTwoPng2x,
    deskImgWebp: deskImgTwoWebp,
    deskImgWebp2x: deskImgTwoWebp2x,
    mdImgPng: mdImgTwoPng,
    mdImgPng2x: mdImgTwoPng2x,
    mdImgWebp: mdImgTwoWebp,
    mdImgWebp2x: mdImgTwoWebp2x,
    tabImgPng: tabImgTwoPng,
    tabImgPng2x: tabImgTwoPng2x,
    tabImgWebp: tabImgTwoWebp,
    tabImgWebp2x: tabImgTwoWebp2x,
    mobImgPng: mobImgTwoPng,
    mobImgPng2x: mobImgTwoPng2x,
    mobImgWebp: mobImgTwoWebp,
    mobImgWebp2x: mobImgTwoWebp2x,
  },
  paragraphs: [
    [
      { textParagraph: "AMZScout Product Database" },
      {
        textParagraph:
          "The AMZScout Product Database contains data for over 600 million Amazon products. The database makes it easy to find items that match your exact criteria with dozens of advanced filters you can use to refine your search.",
      },
      {
        textParagraph:
          "When you enter a search, the web app shows matching products and their estimated sales, estimated revenue, price, FBA fees, and other important information.",
      },
    ],
    [
      { textParagraph: "AMZScout PRO Extension" },
      {
        textParagraph:
          "The AMZScout PRO Extension gives you the ability to analyze products and niches while on the Amazon marketplace. The Chrome extension is easy to use and will show you the same crucial data available in the Product Database.",
      },
      {
        textParagraph:
          "The PRO Extension provides additional insights including the niche score and niche history. These let you quickly assess the overall viability of a market and include a list of where the niche is strong and where it is weak. The AMZScout extension also has a product score for both private label and reselling so you can see what works best given your sourcing method.",
      },
    ],
    [
      { textParagraph: "Amazon Dropshipping, Arbitrage, and Wholesale Tool" },
      {
        textParagraph:
          "Amazon Dropshipping, Arbitrage, and Wholesale Product Research tool helps dropshippers, wholesalers, and arbitrage sellers quickly find product ideas that work with their business model. As a reseller, you can use the tool to check a product's price across all sellers to see if you can win the buy box with your target sales price. The extension also includes a built-in calculator, where you can enter your variables to quickly see your potential sales and profitability.",
      },
    ],
  ],
}
export default AMZS_CONTENT
