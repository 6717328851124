import React from "react";
import TITLE_CONTENT from "./index.content";
import "./index.scss";

const Title = () => (
  <div className="PgAVJS-BP-Title">
    <h2 className="PgAVJS-BP-Title__title">Pricing</h2>
    {TITLE_CONTENT.map((text, i) => (
      <p key={i} className="PgAVJS-BP-Title__text">
        {text}
      </p>
    ))}
  </div>
);

export default Title;
