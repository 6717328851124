import React from "react"

const PG_AVJS_SECTION_THREE_CONTENT = {
  listParagraphs: [
    [
      { textParagraph: "Amazon Sales Estimator" },
      {
        textParagraph:
          "This tool provides an estimate of a product’s monthly sales given a particular sales rank",
      },
    ],
    [
      { textParagraph: "FBA Calculator" },
      {
        textParagraph:
          "This tool shows the storage and fulfillment fees for any product on Amazon. With the calculator, you can enter your own variables to see your potential profit.",
      },
    ],
    [
      { textParagraph: "Stock Stats" },
      {
        textParagraph:
          "This tool lets you see the inventory levels for listings from any seller on Amazon",
      },
    ],
  ],
}

export default PG_AVJS_SECTION_THREE_CONTENT
