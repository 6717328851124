import React from "react";
import Title from "./Title";
import Compare from "./Compare";
import Board from "./Board";
import "./index.scss";

const ProductResearch = () => (
  <section className="PgAVJS-PR">
    <div className="PgAVJS-PR__wrapRowOne">
      <div className="container">
        <Title />
        <Compare />
      </div>
    </div>
    <div className="PgAVJS-PR__wrapRowTwo">
      <div className="container">
        <Board />
      </div>
    </div>
  </section>
);

export default ProductResearch;
