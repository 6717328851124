import React from "react";
import JUNGLE_CONTENT from "./index.content";
import GetList from "../../../GetList";
import GetScreen from "../../../GetScreen";
import "./index.scss";

const Jungle = () => {
  const { screen, paragraphs } = JUNGLE_CONTENT;
  return (
    <div className="PgAVJS-PR-Jungle">
      <GetScreen classes="PgAVJS-PR-Jungle__imgWrap" listPictures={screen} />
      <div className="PgAVJS-PR-Jungle__textWrap">
        <p className="PgAVJS-PR-Jungle__title">
          Jungle Scout Product Research Tools
        </p>
        <GetList
          list={paragraphs}
          classes="PgAVJS-PR-Jungle__listItem"
          classesImg="PgAVJS-PR-Jungle__marker"
        />
      </div>
    </div>
  );
};

export default Jungle;
