import React from "react";
import "./index.scss";

const Title = () => (
  <div className="PgAVJS-PR-Title">
    <h2 className="PgAVJS-PR-Title__title">Product Research</h2>
    <p className="PgAVJS-PR-Title__text">
      Next, we will look at how the two platforms compare when it comes to
      product research. Both offer subscribers several tools for finding
      profitable products to sell on Amazon.
    </p>
  </div>
);

export default Title;
