import React from "react";
import PG_AVJS_SECTION_THREE_CONTENT from "./index.content";
import GetList from "../GetList";
import "./index.scss";

const SectionThree = () => {
  const { listParagraphs } = PG_AVJS_SECTION_THREE_CONTENT;

  return (
    <section className="PgAVJS-SectionThree">
      <div className="container">
        <h2 className="PgAVJS-SectionThree__title">
          Other Free Product Research Tools from AMZScout
        </h2>
        <GetList
          list={listParagraphs}
          classes="PgAVJS-SectionThree__listItem"
          classesImg="PgAVJS-SectionThree__marker"
        />
      </div>
    </section>
  );
};

export default SectionThree;
