import React from "react";

const BOARD_CONTENT = {
  firstPartList: [
    [
      { textParagraph: "AMZScout Database Features More Product Listings" },
      {
        textParagraph:
          "To start, AMZScout’s product database contains details for more items in Amazon’s catalog. This enables sellers to conduct more thorough research, as their searches for products will have additional options to surface."
      }
    ],
    [
      { textParagraph: "New and Trending Filters" },
      {
        textParagraph:
          "AMZScout has a couple of extra features to streamline the product research process. These are the New Products and Trending Products filters."
      },
      {
        textParagraph:
          "You can select the filters to have the data table only show products that match that trait. This allows you to quickly review potential products to find those that meet your desired characteristics."
      }
    ],
    [
      { textParagraph: "Data Accuracy" },
      {
        textParagraph:
          "Because this data comes from the providers themselves, we do not know how reliable it is. However, research from third parties has consistently shown that Jungle Scout tends to overestimate a product’s sales, while AMZScout tends to underestimate them."
      },
      {
        textParagraph:
          "Underestimated sales numbers are preferred, as they are less risky, and you won’t end up planning for more sales than what you can actually get",
        more: true
      }
    ]
  ],
  secondPartList: [
    [
      { textParagraph: "Weekly Trend Report" },
      {
        textParagraph:
          "With AMZScout, you get a Weekly Trend Report - it’s displayed in the AMZScout Web App interface. You can use the Trend Report to find ideas of promising niches. The niches are selected by an algorithm according to those with the strongest trends."
      }
    ],
    [
      { textParagraph: "Listing Quality Score" },
      {
        textParagraph:
          "LQS is an important metric for seeing if a niche is filled with products with high-quality listings that will be difficult to compete with."
      },
      {
        textParagraph:
          "With that in mind, the LQS feature is much more efficient with AMZScout.  Their score uses a point system that ranges between 1 to 100. Jungle Scout’s score only goes from 1 to 5. AMZScout gives sellers a more detailed idea of how well the listing is made."
      }
    ],
    [
      {
        textParagraph:
          "Product Research for Dropshippers, Wholesalers, and Arbitrage Sellers"
      },
      {
        textParagraph:
          "AMZScout is a better fit for a variety of sellers. Jungle Scout offers many features, but they are all geared towards private label sellers. This causes a problem for anyone looking to use any other sourcing method."
      }
    ]
  ]
};
export default BOARD_CONTENT;
