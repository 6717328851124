import React from "react";
import Title from "./Title";
import Compare from "./Compare";
import Board from "./Board";
import "./index.scss";

const BestPricing = ({ pricingRef }) => (
  <section className="PgAVJS-BP">
    <div className="PgAVJS-BP__wrapRowOne">
      <div className="container">
        <Title />
        <Compare pricingRef={pricingRef} />
      </div>
    </div>
    <div className="PgAVJS-BP__wrapRowTwo">
      <div className="container">
        <Board />
      </div>
    </div>
  </section>
);

export default BestPricing;
