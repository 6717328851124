import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const GetList = ({ list, classes, img, classesImg }) => (
  <ul>
    {list.map((t, i) => (
      <li key={i}>
        {img ? <img src={img} alt="." /> : <div className={classesImg ?? ""} />}
        <div className={classes}>
          {t.map((obj, i) =>
            obj.more ? (
              <p key={i}>
                {obj.textParagraph}{" "}
                <Link to="/blog/amzscout-vs-jungle-scout-accuracy-comparison/">
                  more
                </Link>
              </p>
            ) : (
              <p key={i}>{obj.textParagraph}</p>
            )
          )}
        </div>
      </li>
    ))}
  </ul>
);
GetList.propTypes = {
  list: PropTypes.array,
  classes: PropTypes.string,
  classesImg: PropTypes.string,
  img: PropTypes.string,
};
export default GetList;
