import React from "react";
import SVG_IMG_ONE from "../../../assets/images/vector/pages/amzscout-vs-jungle-scout/FreeTrial_01.svg";
import SVG_IMG_TWO from "../../../assets/images/vector/pages/amzscout-vs-jungle-scout/FreeTrial_02.svg";
import "./index.scss";

const FreeTrial = () => {
  return (
    <section className="PgAVJS-FT">
      <div className="container">
        <div className="PgAVJS-FT-sectionTitle">
          <h2 className="PgAVJS-FT-sectionTitle__title">Free Trial</h2>
          <p className="PgAVJS-FT-sectionTitle__text">
            Beyond affordability, it is important for an Amazon product research
            tool to offer a free trial. You never know if a solution will be
            right for you.
          </p>
          <p className="PgAVJS-FT-sectionTitle__text">
            That being said, it can be incredibly useful to try it out before
            you have to pay.
          </p>
        </div>
        <div className="PgAVJS-FT-columWrap">
          <div className="PgAVJS-FT-columOne">
            <div className="PgAVJS-FT-columOne__imgWrap">
              <img
                className="PgAVJS-FT-columOne__img"
                src={SVG_IMG_ONE}
                alt="img"
              />
            </div>
            <div className="PgAVJS-FT-columOne__textWrap">
              <p className="PgAVJS-FT-columOne__title">Jungle Scout</p>
              <p className="PgAVJS-FT-columOne__text">
                Jungle Scout <b>does not offer a free trial</b>. If you want to
                see how the platform works firsthand, you will need to pay.
              </p>
              <p className="PgAVJS-FT-columOne__text">
                Because Jungle Scout doesn’t offer a free trial, you must
                complete the entire account setup process to begin using the
                tools.
              </p>
            </div>
          </div>
          <div className="PgAVJS-FT-columTwo">
            <div className="PgAVJS-FT-columTwo__imgWrap">
              <img
                className="PgAVJS-FT-columTwo__img"
                src={SVG_IMG_TWO}
                alt="img"
              />
            </div>
            <div className="PgAVJS-FT-columTwo__textWrap">
              <p className="PgAVJS-FT-columTwo__title">AMZScout</p>
              <p className="PgAVJS-FT-columTwo__text">
                AMZScout offers new users a fully-functional free trial. No
                payment details are required upon signing up.
              </p>
              <p className="PgAVJS-SO-columTwo__text">
                With a free trial, AMZScout not only gives you the ability to
                test run the software before you subscribe but also makes it
                easier to get your account up and running. You do not need to
                provide your payment details to begin the free AMZScout trial.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FreeTrial;
