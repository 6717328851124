import React from "react";
import COMPARE_AMZS_CONTENT from "./index.content";
import GetList from "../../../GetList";
import ScrollLink from "../../../../common/ScrollLink";
import "./index.scss";

const AMZS = ({ pricingRef }) => (
  <div className="PgAVJS-BP-AMZS">
    <div className="PgAVJS-BP-AMZS__textWrap">
      <p className="PgAVJS-BP-AMZS__title">AMZScout Pricing</p>
      <GetList
        list={COMPARE_AMZS_CONTENT}
        classes="PgAVJS-BP-AMZS__listItem"
        classesImg="PgAVJS-BP-AMZS__marker"
      />
      <p className="PgAVJS-BP-AMZS__subTitle">
        AMZScout offers a 10-day, money-back guarantee on all plans.
      </p>
      <p className="PgAVJS-BP-AMZS__footnote">
        * Amazon Dropshipping, Arbitrage, and Wholesale Extension
      </p>
      <ScrollLink refParameter={pricingRef} classes="PgAVJS-BP-AMZS__link">
        Сlick here to see the actual pricing table
      </ScrollLink>
    </div>
  </div>
);
export default AMZS;
