import PNG_desk from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_01.png"
import PNG_desk_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_01@2x.png"
import WEBP_desk from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_01.webp"
import WEBP_desk_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_01@2x.webp"
import PNG_md from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_02.png"
import PNG_md_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_02@2x.png"
import WEBP_md from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_02.webp"
import WEBP_md_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_02@2x.webp"
import PNG_tab from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_03.png"
import PNG_tab_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_03@2x.png"
import WEBP_tab from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_03.webp"
import WEBP_tab_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_03@2x.webp"
import PNG_mob from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_04.png"
import PNG_mob_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_04@2x.png"
import WEBP_mob from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_04.webp"
import WEBP_mob_2x from "../../../../../assets/images/raster/pages/amzscout-vs-jungle-scout/SectionTwo/SectionTwo_01_04@2x.webp"

const JUNGLE_CONTENT = {
  screen: {
    deskImgPng: PNG_desk,
    deskImgPng2x: PNG_desk_2x,
    deskImgWebp: WEBP_desk,
    deskImgWebp2x: WEBP_desk_2x,
    mdImgPng: PNG_md,
    mdImgPng2x: PNG_md_2x,
    mdImgWebp: WEBP_md,
    mdImgWebp2x: WEBP_md_2x,
    tabImgPng: PNG_tab,
    tabImgPng2x: PNG_tab_2x,
    tabImgWebp: WEBP_tab,
    tabImgWebp2x: WEBP_tab_2x,
    mobImgPng: PNG_mob,
    mobImgPng2x: PNG_mob_2x,
    mobImgWebp: WEBP_mob,
    mobImgWebp2x: WEBP_mob_2x,
  },
  paragraphs: [
    [
      { textParagraph: "Jungle Scout Product Database" },
      {
        textParagraph:
          "The Jungle Scout Product Database contains data on hundreds of millions of Amazon products. You can use the tool to search and analyze products based on a variety of criteria and metrics such as sales, price, revenue, and more.",
      },
    ],
    [
      { textParagraph: "Jungle Scout Browser Extension" },
      {
        textParagraph:
          "The Jungle Scout Browser Extension lets you analyze product data while looking at items on Amazon.com. You can use it to review much of the same performance metrics offered by the Database.",
      },
    ],
  ],
}
export default JUNGLE_CONTENT
