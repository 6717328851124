import React from "react";
import Jungle from "./Jungle";
import AMZS from "./AMZS";
import "./index.scss";

const Compare = ({ pricingRef }) => (
  <div className="PgAVJS-BP__Compare">
    <Jungle />
    <AMZS pricingRef={pricingRef} />
  </div>
);
export default Compare;
