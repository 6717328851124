import React from "react"

const PG_AVJS_SECTION_FIVE_CONTENT = {
  listParagraphs: [
    [
      { textParagraph: "Amazon Seller Course for Private Label" },
      {
        textParagraph:
          "This free resource contains video lessons walking you through all the steps you need to take to begin private label selling on Amazon",
      },
    ],
    [
      {
        textParagraph:
          "Seller Training for Dropshippers, Wholesalers, and Arbitrage Sellers",
      },
      {
        textParagraph:
          "This course teaches how to sell using dropshipping, arbitrage, or wholesale.",
      },
    ],
    [
      { textParagraph: "Amazon Seller Masterclasses" },
      {
        textParagraph:
          "These classes feature lessons taught by successful six and seven-figure Amazon sellers. During the classes, the instructors provide thorough training for different parts of running an Amazon business.",
      },
    ],
  ],
}
export default PG_AVJS_SECTION_FIVE_CONTENT
