import React from "react";
import { PAGES } from "../../../helpers/constants";
import { PG_AVJS_FORM_TWO_CONTENT } from "../../common/Forms/SectionForm.content";
import StandardEmailCollectContainer from "../../../containers/common/forms/StandardEmailCollectContainer";
import GetScreen from "../GetScreen";
import "./index.scss";

const FormTwo = () => {
  return (
    <section className="PgAVJS-FormTwo">
      <div className="container">
        <div className="PgAVJS-FormTwo__wrap">
          <div className="PgAVJS-FormTwo__wrapForm">
            <StandardEmailCollectContainer
              page={PAGES.AMZSCOUT_VS_JUNGLE_SCOUT}
              buttonTextFirstState="Try AMZScout for free!"
              buttonTextSecondState="Try AMZScout for free!"
              title="Find profitable products today with a Product Database and PRO Extension FREE TRIAL!"
              placeholderText="example@gmail.com"
              isNewForm
              staticTopTitle="Find profitable products today with a Product Database and PRO Extension FREE TRIAL!"
              customClass="PgAVJS-FormTwo__emailCollect"
            />
          </div>
          <GetScreen
            classes="PgAVJS-FormTwo__img"
            listPictures={PG_AVJS_FORM_TWO_CONTENT}
            isSectionForm
          />
        </div>
      </div>
    </section>
  );
};

export default FormTwo;
