import React from "react";
import COMPARE_JUNGLE_CONTENT from "./index.content";
import GetList from "../../../GetList";
import "./index.scss";

const Jungle = () => (
  <div className="PgAVJS-BP-Jungle">
    <div className="PgAVJS-BP-Jungle__textWrap">
      <p className="PgAVJS-BP-Jungle__title">Jungle Scout Pricing</p>
      <GetList
        list={COMPARE_JUNGLE_CONTENT}
        classes="PgAVJS-BP-Jungle__listItem"
        classesImg="PgAVJS-BP-Jungle__marker"
      />
      <p className="PgAVJS-BP-Jungle__subTitle">
        Jungle Scout offers a 7-day, money-back guarantee on all plans.
      </p>
    </div>
  </div>
);

export default Jungle;
