import React, { useRef } from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen-kwTracker-intro@xxl.png";
import Intro from "../components/amzscout-vs-jungle-scout/Intro";
import FreeTrial from "../components/amzscout-vs-jungle-scout/FreeTrial";
import ProductResearch from "../components/amzscout-vs-jungle-scout/ProductResearch";
import SectionThree from "../components/amzscout-vs-jungle-scout/SectionThree";
import KeywordResearch from "../components/amzscout-vs-jungle-scout/KeywordResearch";
import SectionFive from "../components/amzscout-vs-jungle-scout/SectionFive";
import BestPricing from "../components/amzscout-vs-jungle-scout/BestPricing";
import BetterTool from "../components/amzscout-vs-jungle-scout/BetterTool";
import FormOne from "../components/amzscout-vs-jungle-scout/FormOne";
import FormTwo from "../components/amzscout-vs-jungle-scout/FormTwo";
import FormThree from "../components/amzscout-vs-jungle-scout/FormThree";
import FormFour from "../components/amzscout-vs-jungle-scout/FormFour";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import "../components/amzscout-vs-jungle-scout/index.scss";

const PgAVJS = () => {
  const pricingRef = useRef(null);

  return (
    <Layout customClass="PgAVJS-page" tariffsElement={pricingRef}>
      <Seo
        title="AMZScout vs Jungle Scout: Comparison of Tools, Features and Pricing"
        description="How can you choose between AMZScout and Jungle Scout? What is the best product and keyword research software available at an affordable price? A Chrome extensions and other tools comparison."
        page="amzscout-vs-jungle-scout"
        manifest="browserconfig.xml"
        ogImg={OgImg}
        keywords={["amzscout vs jungle scout", "jungle scout vs amzscout"]}
      />
      <Intro />
      <FreeTrial />
      <FormOne />
      <ProductResearch />
      <FormTwo />
      <SectionThree />
      <KeywordResearch />
      <FormThree />
      <SectionFive />
      <BestPricing pricingRef={pricingRef} />
      <BetterTool />
      <FormFour />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="PgAVJS-Pricing"
        subTitlePro
      />
    </Layout>
  );
};

export default PgAVJS;
