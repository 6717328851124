import React from "react";
import SVG_LOGOS from "../../../assets/images/vector/pages/amzscout-vs-jungle-scout/Intro_logos.svg";
import "./index.scss";

const Intro = () => {
  return (
    <section className="PgAVJS-I">
      <div className="PgAVJS-I__wrapRowOne">
        <div className="container">
          <div className="PgAVJS-I-rowOne">
            <h1 className="PgAVJS-I-rowOne__title">AMZScout vs Jungle Scout</h1>
            <p className="PgAVJS-I-rowOne__subTitle">Which tool is better?</p>
            <p className="PgAVJS-I-rowOne__text">
              Today, it is easier to get started selling on Amazon than ever
              before. With so many Amazon seller tools available, new sellers
              can learn all they need to know about a potential product or niche
              before they begin selling.
            </p>
            <img className="PgAVJS-I-rowOne__img" src={SVG_LOGOS} alt="" />
          </div>
        </div>
      </div>
      <div className="PgAVJS-I__wrapRowTwo">
        <div className="container">
          <div className="PgAVJS-I-rowTwo">
            <div className="PgAVJS-I-rowTwo__wrapText">
              <p className="PgAVJS-I-rowTwo__title">
                AMZScout and Jungle Scout are two of the most popular Amazon
                seller tools due to their extensive featured sets and robust
                sales data.
              </p>
              <p className="PgAVJS-I-rowTwo__text">
                While there are many similarities between the two platforms,
                there are some notable differences you’ll want to be aware of
                before you choose one to plan and manage your Amazon business.
              </p>
              <p className="PgAVJS-I-rowTwo__text">
                In this post, we will compare AMZScout and Jungle Scout to see
                how they stack up to one another.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
