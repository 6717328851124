import React from "react";
import Jungle from "./Jungle";
import AMZS from "./AMZS";
import "./index.scss";

const Compare = () => (
  <div className="PgAVJS-PR__Compare">
    <Jungle />
    <AMZS />
  </div>
);

export default Compare;
